<template >
  <div class="pitchyawBox">
    <!-- 旧版姿态球 -->

    <div v-show="false">

      <div class="pa" style="left: 40px; top: 14px">
        <div class="infoBox cf">
          <div class="dib title">{{ props_obj.relativeAlt ? props_obj.relativeAlt.toFixed(2) : 0 }}</div>
          <div class="cf info" style="transform: translateX(-9px);">飞行高度（m）</div>
        </div>
        <div class="line mt9 mb7"></div>
        <div class="infoBox cf">
          <div class="dib title">
            {{
              uavData && uavData.distanceToHome
              ? uavData.distanceToHome.toFixed(2)
              : 0
            }}
          </div>
          <div class="cf info">起点距离</div>
        </div>
      </div>
      <div class="pa" style="right: 71px; top: 14px">
        <div class="infoBox cf">
          <div class="dib title">{{ props_obj.groundSpeed ? props_obj.groundSpeed.toFixed(2) : 0 }}</div>
          <div class="cf info">飞行速度(m/s)</div>
        </div>
        <div class="line2 mt9 mb7"></div>
        <div class="infoBox cf">
          <div class="dib title">
            {{
              props_obj.flyTime ? (props_obj.flyTime / 60).toFixed(2) : 0
            }}
          </div>
          <div class="cf info">飞行时间(min)</div>
        </div>
      </div>
      <div class="pa dib w426" style="top:-35px">
        <!-- <img class="wih100 h2" src="~@/assets/images/observe/fckernel/line.png" alt /> -->
      </div>
      <div class="pa dib w426" style="bottom:-27px">
        <!-- <img class="wih100 h2" src="~@/assets/images/observe/fckernel/line.png" alt /> -->
      </div>

      <div class="pitchyaw h123"></div>
      <ball :uav-data="uavData" class="dib ball pa"></ball>
    </div>

    <!-- 新版姿态球 -->

    <div class="middle" v-show="true">

      <div class="item item1">
        <div class="left-value">
          <div class="vv v1">{{ uavData ? parseFloat(uavData.uavInfo.pitch).toFixed(1) : 0 }} °</div>
          <div class="vv v2">
            <div class="t1">
              <span class="t1-name">SPD</span>
              <span class="t1-unit">m/S</span>
            </div>
            <div class="t2">{{ uavData ? parseFloat(uavData.uavInfo.groundSpeed).toFixed(1) : 0 }}</div>
            <div class="t3">
              <div class="flag"></div>
            </div>
          </div>
          <div class="vv v3">
            <span class="name">WS</span>
            <span class="value"></span>
            <img src="@/assets/img/i52.png" alt="" class="img">
          </div>
        </div>
        <div class="right-legth">
          <div class="bar">
            <div class="bar-divide"></div>
            <div class="bar-divide"></div>
            <div class="bar-divide"></div>
            <div class="bar-divide"></div>
            <div class="bar-divide"></div>
          </div>
        </div>
      </div>
      <div class="item item2">
        <span class="yaw-text">{{uavData && uavData.uavInfo.yaw ? parseFloat(uavData.uavInfo.yaw).toFixed(2) : 0 }}</span>
        <img class="trangle" src="@/assets/img/i21.png" alt="">
        <img src="@/assets/img/i53.png" alt="" class="img"
          :style="{ rotate: (uavData && uavData.uavInfo.yaw ? parseFloat(uavData.uavInfo.yaw).toFixed(2) : 0) + 'deg' }">
        <!-- <img src="@/assets/img/i53.png" alt="" class="img"> -->
        <img src="@/assets/img/i54.png" alt="" class="img-center">
        <img src="@/assets/img/i55.png" alt="" class="img-line">
        <div class="h">H</div>
      </div>
      <div class="item item3">
        <div class="right-legth">
          <div class="bar">
            <div class="bar-divide bar-divide1"></div>
            <div class="bar-divide bar-divide2"></div>
            <div class="bar-divide bar-divide3">
              <div class="up"></div>
              <div class="down"></div>
            </div>
          </div>
          <div class="b-t">
            <div class="h">H</div>
            <div class="v">{{ uavData && uavData.uavInfo.distanceToHome ? uavData.uavInfo.distanceToHome.toFixed(2) : 0
            }}m</div>
          </div>
        </div>
        <div class="left-value">
          <div class="vv v1">
            <span class="v">{{ uavData && parseFloat(uavData.uavInfo.climbRate).toFixed(1) }}</span>
            <span class="u">VS</span>
          </div>
          <div class="vv v2">
            <div class="t2">{{ uavData && parseFloat(uavData.uavInfo.height).toFixed(1) }}</div>
            <div class="t1">
              <span class="t1-name">ALT</span>
              <span class="t1-unit">m</span>
            </div>
          </div>
          <div class="vv v3">
            <span class="value">{{ uavData && parseFloat(uavData.uavInfo.altitude).toFixed(1) }}</span>
            <span class="name">ALS</span>
            <!-- <img src="@/assets/img/i52.png" alt="" class="img"> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import ball from "@/components/observe/fckernel/fckernelVideos/ball";
export default {
  props: {
    uavData: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    deep: true,
    uavData (val) {
      // console.log(val, '=================');
      // return val.uavInfo;
      // this.imgYaw = this.uavDataNew.yaw ? parseFloat(this.uavDataNew.yaw).toFixed(2) : 0;
    }
  },
  mounted () {
    console.log(this.uavData, 'xXXXuavDatauavData.............');
  },
  data () {
    return {
      imgYaw: 0,
    };
  },
  components: { ball },
  computed: {
    props_obj () {
      let { uavData } = this;

      if (
        uavData &&
        uavData.attitude &&
        uavData.locationCoordinate3D &&
        uavData.gps
      ) {
        let sleep = 0;
        let x = uavData.velocityX * uavData.velocityX;
        let y = uavData.velocityY * uavData.velocityY;
        let z = uavData.velocityZ * uavData.velocityZ;
        sleep = Math.sqrt(x + y + z);
        return {
          ...uavData.attitude,
          ...uavData.gps,
          sleep,
          groundSpeed: uavData.groundSpeed,
          ...uavData.locationCoordinate3D,
          flyDistance: uavData.flyDistance,
          flyTime: uavData.flyTime / 1000,
          velocityX: uavData.velocityX,
          velocityY: uavData.velocityY,
          velocityZ: uavData.velocityZ
        };
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.pitchyawBox {
  position: relative;
  // width: 100%;
  // border: 1px solid red;
  // height: 100%;

  .middle {
    width: 100%;
    height: 175px;
    border-radius: 8px;
    // border: 1px solid #747776;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 10px 10px;

    .item {
      height: 100%;
      // border: 1px solid yellow;
      box-sizing: border-box;
    }

    .item1 {
      width: 32%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .left-value {
        width: 74%;
        height: 65%;
        // border: 1px solid red;
        position: relative;

        .vv {
          position: absolute;
        }

        .v1 {
          right: 0px;
          top: 14%;
          height: 35%;
          color: #fff;
        }

        .v2 {
          right: 0px;
          top: 30%;
          // border: 1px solid red;
          width: 100%;
          height: 35%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row;

          .t1 {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .t1-name {
              font-size: 13px;
              font-family: Helvetica-Bold, Helvetica;
              font-weight: bold;
              color: #12FFD8;
            }

            .t1-unit {
              margin-top: 3px;
              font-size: 13px;
              font-family: Helvetica-Bold, Helvetica;
              font-weight: bold;
              color: #12FFD8;
            }
          }

          .t2 {
            width: 65%;
            font-size: 25px;
            font-family: Helvetica;
            color: #12FFD8;
            line-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .t3 {
            width: 5%;
            position: relative;
            margin-right: 2px;

            .flag {
              position: absolute;
              top: -6px;
              right: -10px;
              width: 11px;
              height: 11px;
              background: #3BDCEE;
              // width: 0;
              // height: 0;
              // border-left: 11px solid transparent;
              // border-right: 11px solid transparent;
              // border-bottom: 100px solid red;
            }
          }
        }

        .v3 {
          width: 100%;
          height: 30%;
          right: 0px;
          top: 65%;
          // border: 1px solid red;
          width: 100%;
          height: 30%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row;

          .name,
          .value {
            font-size: 13px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #FFFFFF;
          }

          .img {
            width: 15px;
            height: 17px;
            transform: rotate(45deg);
          }
        }
      }

      .right-legth {
        width: 26%;
        height: 65%;
        // border: 1px solid rgb(0, 162, 255);
        display: flex;
        align-items: center;
        justify-content: center;

        .bar {
          width: 12px;
          height: 100%;
          background-color: #656B76;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;

          .bar-divide {
            width: 100%;
            height: 2px;
            background-color: #fff;
          }
        }
      }
    }

    .item2 {
      width: 34%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .yaw-text {
        position: absolute;
        top: -9px;
        left: 33%;
        color: #ffffff;
        font-size: 13px;
      }

      .trangle {
        position: absolute;
        top: 6px;
        left: 42%;
        color: #ffffff;
        font-size: 13px;
        z-index: 300;
      }

      .img {
        transform: scale(.8);
      }


      .img-center {
        position: absolute;
        top: 46%;
        left: 42%;
      }

      .img-line {
        position: absolute;
        top: 30%;
        left: 35%;
        height: 36px;
        transform: rotate(30deg);
        transform-origin: bottom;
      }

      .h {
        width: 18px;
        height: 18px;
        background: transparent;
        border-radius: 50%;
        position: absolute;
        bottom: 28%;
        right: 24%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #FFDA00;
        border: 2px solid #FFDA00;
      }

    }

    .item3 {
      width: 32%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .left-value {
        width: 74%;
        height: 65%;
        // border: 1px solid red;
        position: relative;

        .vv {
          position: absolute;
        }

        .v1 {
          right: 0px;
          top: 14%;
          height: 35%;
          color: #fff;
          // background-color: #3CE171;
          margin-top: -5px;

          .v,
          .u {
            font-size: 13px;
            font-family: Helvetica;
            color: #FFFFFF;
          }

          .u {
            margin-left: 5px;
          }
        }

        .v2 {
          right: 0px;
          top: 30%;
          // border: 1px solid red;
          width: 100%;
          height: 35%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row;
          // background-color: #508b82;

          .t1 {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .t1-name {
              font-size: 13px;
              font-family: Helvetica-Bold, Helvetica;
              font-weight: bold;
              color: #12FFD8;
            }

            .t1-unit {
              margin-top: 3px;
              font-size: 13px;
              font-family: Helvetica-Bold, Helvetica;
              font-weight: bold;
              color: #12FFD8;
            }
          }

          .t2 {
            width: 65%;
            font-size: 25px;
            font-family: Helvetica;
            color: #12FFD8;
            line-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .t3 {
            width: 5%;
            position: relative;

            .flag {
              position: absolute;
              top: -6px;
              right: -10px;
              width: 11px;
              height: 11px;
              background: #3BDCEE;
              // width: 0;
              // height: 0;
              // border-left: 11px solid transparent;
              // border-right: 11px solid transparent;
              // border-bottom: 100px solid red;
            }
          }
        }

        .v3 {
          width: 100%;
          height: 30%;
          right: 0px;
          top: 65%;
          // border: 1px solid red;
          width: 100%;
          height: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row;

          .name,
          .value {
            font-size: 13px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #FFFFFF;
          }

          .name {
            margin-left: 10px;
          }


        }
      }

      .right-legth {
        width: 26%;
        height: 65%;
        // border: 1px solid rgb(0, 162, 255);
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;

        .bar {
          width: 12px;
          height: 100%;
          // background-color: #656B76;
          display: flex;
          align-items: center;
          flex-direction: column;

          .bar-divide {
            width: 100%;
            // background-color: #fff;
          }

          .bar-divide1 {
            height: calc(50% - 2px);
            background-color: #656B76;
          }

          .bar-divide2 {
            height: 2px;
            background-color: #fff;
            margin: 1px 0 1px 0;
          }

          .bar-divide3 {
            height: calc(50% - 2px);

            .up {
              width: 100%;
              height: 50%;
              background-color: #DFA226;
            }

            .down {
              width: 100%;
              height: 50%;
              background-color: #DF3326;
            }
          }

        }

        .b-t {
          position: absolute;
          top: 100%;
          height: 40px;
          width: 100%;
          // border: 1px solid pink;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          // bottom: 10%;

          .h {
            width: 18px;
            height: 18px;
            background: transparent;
            border-radius: 50%;
            position: absolute;
            bottom: 25%;
            right: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #FFDA00;
            border: 2px solid #FFDA00;
          }

          .v {
            background: transparent;
            position: absolute;
            bottom: -25%;
            right: 0%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #FFDA00;
          }
        }
      }
    }
  }
















  .pitchyaw {
    background-image: linear-gradient(90deg,
        rgba(0, 138, 212, 0) 0%,
        #008ad4 49%,
        rgba(0, 138, 212, 0) 100%);
  }

  .ball {
    top: -17px;

    margin-left: calc(50% - 100px);
  }

  .line {
    width: 91px;
    height: 0.1px;
    opacity: 0.6;
    border: 1px solid;
    border-image: linear-gradient(63deg, rgba(135, 217, 255, 0), rgba(135, 217, 255, 1)) 1 1;
  }

  .line2 {
    width: 91px;
    height: 0.1px;
    opacity: 0.6;
    border: 1px solid;
    border-image: linear-gradient(63deg, rgba(135, 217, 255, 1), rgba(135, 217, 255, 0)) 1 1;
  }

  .infoBox {
    width: 100px;
    text-align: center;

    .title {
      font-family: UniDreamLED;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 1px;
      font-weight: 400;
    }

    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #78bcea;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
</style>