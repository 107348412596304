<template>
  <div class="page-observe-fckernel-uav">
    <Header :uav-data="uav.control.data"></Header>

    <!-- 视频墙无人机和机巢列表 -->
    <div class="fckernelVideos_leftBox">

      <!-- 标题 -->
      <div class="dib wih100 h54 fckernelVideos_leftBox_title">
        <!-- <img @click="changeList" class="dib top5 cp title h40 pa left100" src="./img/title.png" alt />
        <div class="fckernelVideos_yc cf lh57 cp tc" @click="changeList('yc')">
          机巢列表
        </div> -->
        <div class="title title1" :class="[titleSelectedFlag == 0 ? 'title-selected' : '']"
          @click="clickTitle(0); changeList()">无人机列表
        </div>
        <div class="title title2" :class="[titleSelectedFlag == 1 ? 'title-selected' : '']"
          @click="clickTitle(1); changeList('yc')">机巢列表
        </div>
      </div>

      <!-- 分屏按钮 隐藏-->
      <div class="videoBtn" v-if="false">
        <div v-for="(item, index) in videoBtnList" :class="uav.itemkey == item.id ? 'videoBtn_active' : ''" :key="index"
          @click="splitScreenFn(item)">
          {{ item.title }}
        </div>
      </div>

      <!-- 无人机查询 -->
      <div v-if="videoType" class="uav-search">
        <el-input class="uav-search__input" clearable placeholder="请输入无人机名称/机构名称" v-model="uavSearchContent"
          v-on:keyup.enter.native="onUavSearch">
        </el-input>
        <el-button class="uav-search__btn" icon="el-icon-search" @click="onUavSearch"></el-button>
      </div>

      <!-- 无人机列表 start -->
      <UavList class="uav-list" v-if="videoType" @videoChange="videoChange" :containerStyle="{
        width: '390',
        height: '538',
      }" :list="uav.list" @fn="uav_fn" style="box-sizing: border-box; padding-left: 10px" @refresh="onUavSearch" />
      <!-- 无人机列表 end -->

      <!-- 机巢列表 start-->
      <div class="cpt-observe-nest-list" v-if="!videoType">
        <div class="ctx-box pl0">
          <div class="list-box pr0">
            <template v-if="list && list.length">
              <Item @fn="uav_fn" v-for="item in list" :key="item.id" :data="item" />
            </template>
          </div>
        </div>
      </div>
      <!-- 机巢列表 end-->

      <!-- 姿态球 -->
      <pitchyaw :uav-data="uav.control.data" :class="isFullscreenBool ? '_full_pitchyaw' : 'pitchyaw'" class="pitchyaw">
      </pitchyaw>
    </div>

    <!-- 右侧大屏 -->
    <div v-if="uav.videos.length > 0" v-loading="loading" element-loading-text="加载中.." class="right-panel"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.9)" :class="{
        videoBox_four: uav.itemkey == 4,
        videoBox_one: uav.itemkey == 1,
        videoBox_nine: uav.itemkey == 9,
        videoBox_sixteen: uav.itemkey == 16,
      }">
      <!-- 分屏按钮 -->
      <div class="split-screen">
        <div class="right">
          <img @click="splitScreenFn(videoBtnList[0])" class="img img1" src="@/assets/img/i97.png" alt="">
          <img @click="splitScreenFn(videoBtnList[1])" class="img img2" src="@/assets/img/i95.png" alt="">
          <img @click="splitScreenFn(videoBtnList[2])" class="img" src="@/assets/img/i96.png" alt="">
          <div @click="splitScreenFn(videoBtnList[3])" class="f-img">16</div>
        </div>
      </div>
      <div class="player" v-for="(video, index) in uav.videos" :key="index" @click="change_active_item(index)">
        <FloatPlayer @exitVideo="exitVideo" class="wih100 ht100" :device="uav.control.device" 
        :class="{ active_item_style: index == uav.active_item, default_item_style: index != uav.active_item, }" 
        :uav-data="uav.control.data" :_index="index" :data="video" />
      </div>
    </div>

    <!-- 接管记录 -->
    <TakeOver @exit="uav.TakeOverFlag = false" v-if="uav.TakeOverFlag"></TakeOver>

    <!-- 侧边栏 -->
    <SideBar :selectedFlag="2" class="side-bar"></SideBar>

    <!-- <AirLineLibrary class="airline-library"></AirLineLibrary> -->


  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UavList from "./components/list";
import FloatPlayer from "./components/float-playerfk";
import data from "./data";
import methods from "./methods";
import Header from "./components/header/index";
import TakeOver from "./components/takeOver";
import pitchyaw from "./components/pitchyaw";
import API from "@/api";
import Item from "./item";
// 侧边栏
import SideBar from '../home/components/sideBar';
// 航线库面板
import AirLineLibrary from '../fckernel/uav/components/taskList/components/airLineLibrary.vue';
import { Utils } from "@/lib/cesium";

import fk from "./fk.json";
export default {
  components: {
    UavList,
    FloatPlayer,
    Header,
    TakeOver,
    Item,
    pitchyaw,
    SideBar,
    AirLineLibrary,
  },
  inject: ["g_cesium_layer"],
  provide () {
    return {
      uav_This: this,
      findList: (search) => {
        if (search) {
          this.uav_list(search);
        } else {
          this.uav_list(search);
          // this.uav_mountList(data.name);
        }
      },
      change_page: () => { },
    };
  },
  data () {
    return {
      ...data(),
      videoType: true, //true 代表无人机 false 代表机巢
      loading: true,
      list: [], //机巢列表
      videoBtnList: [
        {
          title: "单屏",
          id: 1,
        },
        {
          title: "四分屏",
          id: 4,
        },
        {
          title: "九分屏",
          id: 9,
        },
        {
          title: "十六分屏",
          id: 16,
        },
      ],
      videoFlag: true,
      uavSearchContent: "", //无人机搜索内容
      videoList: "", //数据列表
      fk: fk,
      isFullscreenBool: false, //是否是全屏
      titleSelectedFlag: 0, //点击面板顶部标题，无人机列表 0， 机巢列表 1
      active_item_video_name: "", //
    };
  },
  watch: {
    deep: true,
  },
  provide () {
    return {
      fn: (...args) => this.uav_fn(...args),
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
    uav_mounts () {
      let { mounts } = this.uav.control;
      return mounts
        .map((item) => {
          let find_item = this.mount.list.find(
            (m) => m.name === item.gimbalName
          );
          return find_item || undefined;
        })
        .filter((item) => item);
    },
  },
  async mounted () {
    document.title = "视频墙";
    await this.get_uav_videoes();
    this.uav_list(null, true);
    // 机巢列表
    this.nest_list();
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: true,
      type: "fkVideo",
    });
    window.addEventListener("resize", this.fullScreenChangeFn);
    this.uav_init_ws();
    let vDom = document.querySelector('.fkVideo');
    vDom && (vDom.style.left = '80px');
  },
  beforeDestroy () {
    this.uav.control.device = null;
    this.uav.control.device = null;
    this.uav.control.data = null;
    this.uav.control.battery = null;
    this.uav.control.mount = null;
    this.uav.control.mounts = [];
    this.uav.control.mounts = [];
    this.$store.commit("mapmanage/SET_MAP_IS_STATUSl", {
      flag: false,
      type: "fkVideo",
    });
    this.uav.videos = [];
    if (this.mqtt.client) {
      this.mqtt.client.end();
      this.mqtt.client = null;
    }
    window.removeEventListener("resize", this.fullScreenChangeFn);
  },
  methods: {
    ...methods,
    // 点击面板顶部标题
    clickTitle (flag) {
      this.titleSelectedFlag = flag;
    },
    changeList (type) {
      if (type == "yc") {
        this.videoType = false;
      } else {
        this.videoType = true;
      }
      this.get_uav_videoes();
    },
    async nest_list () {
      try {
        let data = await API.NEST.getNestList();
        function each (tree, data) {
          return tree.map((item) => ({
            ...item,
            offLineCount: data.offLineCount,
            onLineCount: data.onLineCount,
            collapse: false,
            child_collapse: false,
            reportStatus: 1,
            child: item.child && item.child.length && each(item.child),
          }));
        }
        if (data.tree && data.tree.length) {
          data = each(data.tree, data);
        }
        this.list = data || [];
      } catch (error) { }
    },
    exitVideo (index) {
      this.uav.videos[index] = {
        id: "",
        deviceName: "",
        deviceHardId: "",
        videos: {},
      };
      this.$forceUpdate();
    },
    change_active_item (index) {
      this.uav.active_item = index;
    },
    splitScreenFn (item) {
      if (item.id != 1) {
        this.loading = true;
      }
      this.uav.itemkey = item.id;
      this.uav.active_item = 0;
      if (item.id == 1) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      } else if (item.id == 4) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      } else if (item.id == 9) {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      } else {
        this.uav.videos = [
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
          { id: "", deviceName: "", deviceHardId: "", videos: {} },
        ];
      }

      setTimeout(() => {
        this.get_uav_videoes();
        this.$forceUpdate();
      }, 1000);
    },
    //监听屏幕是否全屏
    fullScreenChangeFn (e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreenBool = false;
      } else {
        this.isFullscreenBool = true;
      }
    },
    async get_uav_videoes () {
      console.log('视频12');
      let res = await API.HOME.getDeviceStreamAddressList({
        stationType: this.videoType ? 3 : 2,
      });
      console.log(res, '視頻');
      let array = res;
      let videoList = [];
      this.uav.videos = [];
      let videos = [];
      for (let i = 0; i < res.length; i++) {
        videoList.push(array[i].deviceHardId);
        if (videos.length < this.uav.itemkey) {
          videos.push({
            id: i,
            deviceHardId: array[i].deviceHardId,
            deviceName: array[i].deviceName,
            videos: array[i],
          });
        }
      }
      for (let i = 0; i < this.uav.itemkey + 1; i++) {
        if (videos.length < this.uav.itemkey) {
          videos.push({
            id: "",
            deviceName: "",
            deviceHardId: "",
            videos: {},
          });
        }
      }

      this.uav.videoList = videoList;
      this.uav.videos = videos;
      // console.log("视频墙数据！===========", res, videoList, videos);
      setTimeout(() => {
        this.loading = false;
      }, 1500);
      this.$forceUpdate();
    },
    cesium_layer_change_mode (fn, modes) {


      // 定位到芜湖
      Utils.initLocation2WUHU();
      return;

      // fn(modes.GLOBE);
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          120.218241,
          33.165767,
          21812.321476599747
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14,
        },
        time: 5,
      });
    },
    videoChange () {
      this.videoFlag = !this.videoFlag;
    },
    /**
     * 无人机搜索
     */
    onUavSearch () {
      this.uav_list({
        search: this.uavSearchContent,
      });
    },
  },
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
.page-observe-fckernel-uav {
  height: 100%;
  width: 100%;

  // overflow: hidden;
  // background: #183b5e;
  // background: #000000;
  // background: #c21717;
  .fckernelVideos_leftBox {
    // display: none;
    // background-image: linear-gradient(180deg, #1e4d8a 0%, #132148 100%);
    // border: 10px solid #70daf9;
    position: fixed;
    left: 80px;
    height: calc(100% - 287px);
    width: 390px !important;
    width: 410px !important;
    top: 64px;
    z-index: 10;
    background-color: #224D68;

    .fckernelVideos_leftBox_title {
      // background-image: url("./img/fckernelVideos_leftBox_title.png");
      // background-repeat: no-repeat;
      // background-color: #00a7ff;
      width: 100%;
      height: 54px;
      background: #224D68;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      // border: 1px solid yellow;

      .title {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        color: #fff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;

        cursor: pointer;
      }

      .title2 {
        margin-left: 5px;
      }

      .title-selected {
        border-bottom: 2px solid #00F5FF;
        color: #00F5FF;
      }
    }

    .uav-search {
      padding: 0 25px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 7px;
      box-sizing: border-box;
      height: 64px;
      // background-color: #00a7ff;
      // border: 1px solid red;
      // margin-top: 10px;
      background-color: #133E55;

      .uav-search__input ::v-deep {
        width: 317px;
        height: 40px;
        // box-shadow: 0px 2px 4px 0px rgba(28, 94, 124, 0.5),
        //   inset 0px 0px 3px 0px #00b6ff;
        border-radius: 4px;

        .el-input__icon {
          line-height: 40px;
        }

        input {
          height: 40px;
          background: #0A293A;
          color: #fff;
          border: 0;
          // border: 1px solid #00b6ff;

          &::placeholder {
            font-size: 12px;
            color: #898A8E;
          }
        }
      }

      .uav-search__btn {
        // padding: 7px 27px 9px 28px;
        // background: rgba(23, 70, 216, 0.2);
        // box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
        //   inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
        //   inset 0px 0px 4px 0px #00a7ff;
        // border-radius: 4px;
        // border: 1px solid;
        // font-size: 12px;
        // color: #00ffff;
        width: 42px;
        height: 40px;
        background: #4E87AC;
        border-radius: 4px;
        border: 0;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
    }

    .uav-list {
      margin-top: 0;
      padding-left: 0 !important;
      // border: 3px solid rgb(0, 255, 13);
      box-sizing: border-box;
      height: calc(100% - 308px);
    }

    .cpt-observe-nest-list {
      // height: 39vh;
      overflow-y: auto;
      // padding: 0 20px;
      padding: 0;
      box-sizing: border-box;
      height: calc(100% - 244px);
    }

    .pitchyaw {
      // position: fixed;
      // top: 55%;
      bottom: 0;
      // border: 1px solid yellow;
      width: 410px;
      // border: 3px solid rgb(98, 0, 255);
      height: 190px;
      background-color: #0A293A;
    }

    .videoBtn {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      background-color: #07173b;
      width: 80%;
      margin-left: 10%;
      border-radius: 20px;
      border: 1px solid #2267cf;

      div {
        cursor: pointer;
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #fff;

        // border: 1px solid #999;
        border-radius: 20px;
        // margin-right: -1px;
      }

      .videoBtn_active {
        border-radius: 20px;
        color: #fff;
        background-image: url("./img/videoBtn_active.png");
        background-size: 100% 100%;
      }
    }

    ._full_pitchyaw {
      position: fixed;
      top: 64%;
    }

    .fckernelVideos_yc {
      position: absolute;
      height: 45px;
      width: 150px;
      right: 40px;
      background-size: 100% 100%;
      top: 5px;
      background-image: url("./img/ycbj.png");
      background-repeat: no-repeat;
    }
  }

  .videoBox_one {
    // width: 100vw;
    // margin-left: 468px;
    // position: fixed;
    // top: 65px;
    // z-index: 1;
    // height: 80vh;
    // display: flex;
    // flex-wrap: wrap;

    // width: calc(100% - 465px);

    width: calc(100% - 0px);
    // margin-left: 465px;
    margin-left: 490px;
    position: fixed;
    left: 0;
    top: 65px;
    // top: 145px;
    left: 2px;
    padding-bottom: 10px;
    z-index: 1;
    // height: calc(100vh - 80px);
    // height: calc(100vh - 240px);
    display: flex;
    flex-wrap: wrap;

    .player {
      // width: calc(100% - 470px);
      width: 100%;
      height: calc(100vh - 60px);
      position: relative;

      &::v-deep {
        .qingliu {
          canvas {
            height: 98vh;
          }
        }

        .play {
          position: absolute;
          bottom: 0px;
        }

        .video-wrapper {
          inset: 0px;
          height: calc(88vh) !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .split-screen {
    height: 54px;
    width: 100%;
    // background-color: #00a7ff;
    background-color: #1F2030;
    // position: absolute;
    top: 0;
    right: 0;


    .right {
      width: 10%;
      height: 100%;
      float: right;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .img,
      .f-img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }

      .img1,
      .img2 {
        width: 28px;
        height: 28px;
      }

      .f-img {
        box-sizing: border-box;
        border: 2px solid #fff;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        height: 24px;
        width: 24px;
      }
    }
  }

  .active_item_style {
    border: 1px solid red;
  }

  .default_item_style {
    border: 1px solid #eee;
  }

  .right-panel {
    left: 2px !important;
    top: 64px !important;
    width: calc(100% - 490px) !important;
    height: calc(100vh - 64px) !important;
  }


  .videoBox_four {
    // width: calc(100% - 465px);
    width: calc(100% - 470px);
    // margin-left: 465px;
    margin-left: 490px;
    position: fixed;
    left: 0;
    top: 65px;
    // top: 145px;
    left: 2px;
    padding-bottom: 10px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;

    .player {
      // width: calc(98% / 2);
      width: calc(100% / 2);
      // margin: 5px;
      margin: 0px;
      height: 44vh;
      position: relative;
      border: 0;

      &::v-deep {
        .qingliu {
          left: 0;

          canvas {
            height: calc(91.5vh / 2);
          }
        }

        .play {
          bottom: 0px;
        }

        .video-wrapper {
          inset: 0px;
          height: calc(88vh / 2) !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .videoBox_nine {
    // width: calc(100vw - 465px);
    // margin-left: 465px;
    // position: fixed;
    // display: flex;
    // top: 76px;
    // left: 10px;
    // z-index: 1;
    // height: calc(100vh - 80px);
    // flex-wrap: wrap;

    // width: calc(100% - 465px);
    width: calc(100% - 0px);
    // margin-left: 465px;
    margin-left: 490px;
    position: fixed;
    left: 0;
    top: 65px;
    // top: 145px;
    left: 2px;
    padding-bottom: 10px;
    z-index: 1;
    // height: calc(100vh - 80px);
    // height: calc(100vh - 240px);
    display: flex;
    flex-wrap: wrap;

    .player {
      width: calc(100% / 3);
      margin: 0px;
      // border: 1px solid red;
      position: relative;
      height: 29vh;

      &::v-deep {
        .qingliu {
          canvas {
            height: calc(87.5vh / 3);
          }
        }

        .play {
          bottom: 0px;
        }

        .video-wrapper {
          height: calc(88vh / 3) !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .videoBox_sixteen {
    width: calc(100% - 470px);
    // margin-left: 465px;
    margin-left: 490px;
    position: fixed;
    left: 0;
    // top: 65px;
    top: 0px;
    left: 2px;
    padding-bottom: 10px;
    z-index: 1;
    // height: calc(100vh - 80px);
    // height: calc(100vh - 240px);
    display: flex;
    flex-wrap: wrap;

    .player {
      width: calc(100% / 4);
      margin: 0px;
      // border: 1px solid red;
      position: relative;
      height: 22vh;

      &::v-deep {
        .qingliu {
          canvas {
            height: calc(86vh / 4);
          }
        }

        .play {
          bottom: 0px;
        }
      }
    }
  }





  .side-bar {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;

  }
}

.airline-library {
  position: absolute;
  // top: 64px;
  left: 80px;
  // display: none;
  z-index: 121;
  width: 390px;
  height: 850px;
  background: #0A293A;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  bottom: 400px;
}
</style>
