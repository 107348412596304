<template>
  <div ref="wrap">
    <div class="cpt_video" :class="big" v-if="flag" ref="video">
      <div class="video" v-if="data.deviceHardId">
        <div class="name" v-clipboard:copy="vUrl.vUrl" v-show="true">
          <span>{{ data.deviceName }}</span>
        </div>
        <!-- <div class="type" v-if="networkType">{{ networkType }}</div> -->
        <!-- <div class="types">{{ streamName }}</div> -->
      </div>
      <!-- <template v-if="data.deviceHardId">
        <el-tooltip content="模式切换" placement="bottom" v-if="streamSelect !== 'QingLiu'">
          <div class="cp pa iconfont icon-moshiqiehuan cf modelStyle" @click="change"></div>
        </el-tooltip>
      </template> -->
      <components class="player-com" :is="playerCom" :videoFlag="videoFlag" ref="player" :fpvUrl="fpvUrl" :data="vUrl" />
      <!-- <div class="play" v-if="data.deviceHardId">
        <div class="left ml24"></div>
        <div class="right mr14">
          <div class="search mr10">
            <el-select class="video_type mr24" v-model="networkTypeSelect" placeholder="切换网络" style="width: 110px">
              <el-option v-for="item in networkOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
            <el-select class="video_type mr24" v-model="streamSelect" placeholder="切换源">
              <el-option v-for="item in streamOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div class="pointer iconfont icon-gengxin cf f16 mr10" @click="reset"></div>
          <div class="pointer iconfont icon-quanping cf f16" @click="screen"></div>
        </div>
      </div> -->
      <!-- <div v-show="data.deviceHardId" class="f12 pa top5 right5 cp cf" @click="$emit('exitVideo', _index)">
        关闭
      </div> -->
    </div>
  </div>
</template>

<script>


import API from "@/api";

import LiveNVRPlayer from "./livenvr";
import QingLiuPlayer from "./qingliu";
import SRSPlayer from './srs';

import Bus from "@/assets/ligature.js";

export default {
  name: "Float-Playerfk",
  components: {
    QingLiuPlayer,
    LiveNVRPlayer,
    SRSPlayer,
  },
  props: {
    device: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    uavData: {
      type: Object,
      default: () => ({}),
    },
    _index: {
      type: Number,
      default: () => 0,
    },
  },
  data () {
    return {
      flag: true,
      isPlay: true,
      videoFlag: true,
      big: "",
      streamSelect: "", //选择的流
      //选择的网络
      networkTypeSelect: "",
      mountData: {},
    };
  },
  computed: {
    /**
     * 播放器组件名
     */
    playerCom () {
      // if (this.streamSelect === "QingLiu") {
      //   return "QingLiuPlayer";
      // }
      // return "LiveNVRPlayer";

      // 2023年10月9日 11:08:25
      // if (this.vUrl?.vUrl?.includes('pzsp://')) {
      //   return "QingLiuPlayer";
      // }
      // return "LiveNVRPlayer";

      // 2023年10月9日 11:08:35


      let playerName = '';
      if (this.vUrl?.vUrl?.includes('pzsp://')) {
        playerName = "QingLiuPlayer";
      } else if (this.vUrl?.vUrl?.includes('webrtc')) {
        // 播放webrtc
        playerName = "SRSPlayer";
      } else {
        playerName = "LiveNVRPlayer";
      }
      // playerName = "SRSPlayer";
      // console.log('this.data.deviceName', this.data.deviceName);
      // if(this.data.deviceName == 'ZHZ301R22208212'){
      //   playerName = ''
      // }
      // console.log('this.vUrl', this.vUrl);
      // console.log(playerName, 'playerName视频墙');
      return playerName;


    },
    /**
     * 流类型名
     */
    streamName () {
      let find = this.streamOptions.find((item) => {
        return item.value === this.streamSelect;
      });

      return find?.label || "";
    },

    taskId () {
      return this.$store.state.fckernel.taskId;
    },
    /**
     * 可选的网络类型
     */
    networkOptions () {
      let result = [];
      if (this.data.videos?.list?.private) {
        result.push({
          label: "专网",
          value: "private",
        });
      }
      if (this.data.videos?.list?.public) {
        result.push({
          label: "公网",
          value: "public",
        });
      }
      return result;
    },
    /**
     * 网络类型
     * @returns 专网 | 公网
     */
    networkType () {
      // console.log('网络判断', this.data.videos)
      let find = this.networkOptions.find((item) => {
        return item.value === this.networkTypeSelect;
      });

      return find?.label || "";
    },
    /**
     * 流集合
     */
    streams () {
      if (!this.data.videos.list) {
        return null;
      }
      let networkKey1 = "";
      let networkKey2 = "";
      if (this.networkType === "专网") {
        networkKey1 = "private";
        networkKey2 = "public";
      } else {
        networkKey1 = "public";
        networkKey2 = "private";
      }

      let streams = this.data.videos.list[networkKey1];
      if (this.networkType == "" && !streams) {
        streams = this.data.videos.list[networkKey2];
      }
      // console.log('networkKey1', networkKey1, networkKey2, this.data.videos.list[networkKey1], this.data.videos.list[networkKey2])
      return streams;
    },
    /**
     * 流选项
     * @returns [{
          value: "RTC",
          label: "低延迟"
        }]
     */
    streamOptions () {
      let streamOptions = [];
      //清流
      if (this.streams?.qingLiuUrl) {
        streamOptions.push({
          value: "QingLiu",
          label: "原画",
        });
      }

      //WS-FLV
      if (this.streams?.socketUrl) {
        streamOptions.push({
          value: "WS-FLV",
          label: "流畅",
        });
      }

      //WebRtc
      if (this.streams?.webUrl) {
        streamOptions.push({
          value: "WebRtc",
          label: "低延迟",
        });
      }

      return streamOptions;
    },
    /**
     * 播放的视频流
     */
    vUrl () {
      let vUrl = "";
      switch (this.streamSelect) {
        case "QingLiu":
          vUrl = this.streams?.qingLiuUrl;
          break;

        case "WS-FLV":
          vUrl = this.streams?.socketUrl;
          break;

        case "WebRtc":
          vUrl = this.streams?.webUrl;
          break;
      }
      return {
        vUrl,
      };
    },
    fpvUrl () {
      let url = this.streams?.fpvUrl || "";
      return {
        vUrl: url,
        // vUrl: 'webrtc://stream.uatair.com:1990/live/river',
      };
    },
  },
  watch: {
    "data.deviceName": {
      // 低电量，良好，
      handler (value) {
        this.reset();
      },
      deep: true,
    },
    data () {
      try {
        // console.log('this.data.videos', this.data.videos);
        //默认选择存在的网络
        if (this.data.videos.list.private) {
          this.networkTypeSelect = "private";
        } else if (this.data.videos.list.public) {
          this.networkTypeSelect = "public";
        }
      } catch (e) {
        console.log(e);
      }
    },

    "$store.state.device.move_data": function (newVal) {
      this.mountData = newVal;
    },
    streamOptions () {
      //当流选项变化时，如果未选择流类型，则自动选择第一个流类型
      if (this.streamSelect == "" && this.streamOptions.length > 0) {
        this.streamSelect = this.streamOptions[0].value;
      }
      if (this.streamOptions.length === 0) {
        this.streamSelect = "";
      }
    },
    networkType () {
      let find = this.streamOptions.find((item) => {
        return item.value == this.streamSelect;
      });
      if (!find && this.streamOptions[0]) {
        this.streamSelect = this.streamOptions[0].value; //网络类型改变时，未有当前选择的流类型， 则重置
      }
    },
    networkOptions () {
      if (this.networkOptions.length === 0) {
        this.networkTypeSelect = "";
      } else {
        this.networkTypeSelect = this.networkOptions[0].value;
      }
    },
  },
  mounted () {
    window.$uavPlayer = this;
  },
  methods: {
    async change () {
      this.videoFlag = !this.videoFlag;
    },
    screen () {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].toggleFullscreen();
      } else {
        this.$refs.player.fullScreen();
      }
    },
    play () {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].pause();
        this.isPlay = false;
      }
    },
    pause () {
      if (this.playerCom === "LiveNVRPlayer") {
        this.$refs.player.$refs["livePlayer"].play();
        this.isPlay = true;
      }
    },
    reset () {
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    /**
     * 截图
     */
    screenShot () {
      return this.$refs.player.screenShot();
    },
    async photo () {
      // if (!this.taskId) {
      //   this.$message.error("暂无任务！");
      //   return;
      // }
      let blob = this.screenShot();
      let fd = new FormData();
      fd.append("uploadFile", blob, `拍照.png`);
      if (this.taskId) {
        fd.append("taskId", this.taskId);
      }
      fd.append("deviceHardId", this.device.deviceHardId);
      let res = await API.FCKERNEL.Upload(fd);
      console.log(res);
      this.$message.success("拍照成功！");
      this.imgshowurl = process.env.VUE_APP_IMG_URL + "/uploads" + res.fileKey;
      this.$emit("imgUrl", this.imgshowurl);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt_video {
  cursor: default !important;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  // border: 1px solid yellow;

  &:hover {
    .play {
      opacity: 0.7;
    }
  }

  .video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    .name {
      position: absolute;
      top: 0;
      left: 5px;
      // width: 122px;
      height: 26px;
      background: #000000;
      border-radius: 1px;
      opacity: 0.6;
      line-height: 26px;
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .type {
      position: absolute;
      top: 0;
      right: 112px;
      width: 56px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #fc8959;
      border-radius: 13px;
      opacity: 0.58;
      backdrop-filter: blur(6.7px);
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .types {
      position: absolute;
      top: 0;
      right: 50px;
      width: 56px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #4d9dff;
      border-radius: 13px;
      opacity: 0.58;
      backdrop-filter: blur(6.67px);
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 46px;
      height: 26px;
      text-align: center;
      line-height: 26px;
      background: #000000;
      border-radius: 1px;
      opacity: 0.6;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
  }

  .play {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 46px;
    background: #333;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      color: #fff;

      span {
        font-size: 20px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .search {
        display: flex;

        .video_type {
          width: 96px;
          height: 26px;
        }

        .big_type {
          width: 70px;
          height: 26px;
        }

        /deep/.el-input--suffix {
          width: 100%;
          height: 100%;

          .el-input__inner {
            width: 100%;
            height: 100%;
          }
        }

        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.big_01 {
  width: 416px;
  height: 274px;
}

.big_03 {
  width: 1000px;
  height: 600px;
}

.big_04 {
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
}

.ai {
  bottom: 60%;
  z-index: 1;
}

.plate {
  bottom: 45%;
  z-index: 1;
}

.full-video ::v-deep {
  transform: none !important;

  .cpt_video {
    position: relative;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vw;
    z-index: 0 !important;

    .ai {
      top: 400px;
      right: 15px;
      height: 36px;
    }

    .faceAndcar {
      top: 407px;
      height: 22px;
    }

    .plate {
      top: 440px;
      right: 15px;
      height: 36px;
    }
  }
}

.modelStyle {
  bottom: 45px;
  right: 12px;
  z-index: 3;
}

// position: relative;
.faceAndcar {
  z-index: 1;
  width: 55px;
  bottom: 61%;
  display: flex;
  justify-content: space-between;

  .plan {
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0 5px;
    background: rgba(9, 32, 87, 0.7);
  }
}

.player-com {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // border: 1px solid greenyellow;
}
</style>
