<!-- 
  航线库组件
 -->
<template>
  <div class="airline-library-container">
    <div class="header">
      <div>
        <span v-show="airLineLibraryShrinkFlag" class="open iconfont icon-baseqianjin" @click="openClickHandle"></span>
        <span class="name">航线库</span>
      </div>
      <img class="img" src="@/assets/img/i40.png" alt="" @click="close">
    </div>
    <div class="task-search">
      <el-input class="uav-search__input" clearable placeholder="请输入航线名称" v-model="taskName" @clear="handleClickSearch">
      </el-input>
      <el-button class="uav-search__btn" @click="handleClickSearch" icon="el-icon-search"></el-button>
    </div>
    <div class="content">
      <div class="body" id="AirLineBodyID" v-loading="loading" element-loading-text="正在加载数据"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.0)">
        <AirLineCardAuto v-for="(item, index) in lines" :key="index" :data="item" :styleIndex="index"
          :class="[index == selectKey ? 'air-line-card-selected' : '']" @airLineSelectedHandle="airLineSelectedHandle">
        </AirLineCardAuto>
      </div>
    </div>
    <div class="foot" @click="createAirLine">
      <img class="img" src="@/assets/img/i39.png" alt="">
      <span class="name">创建航线</span>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Bus from "@/assets/ligature.js";
import AirLineCardAuto from './alrLineCardAuto';
import { DOM } from '@/utils/dom.js';
import { COMMON } from "@/utils/common.js";
import _ from 'lodash';
let FlightLineList = [];
export default {
  components: {
    AirLineCardAuto,
  },
  props: {
    airLineLibraryShrinkFlag: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    airLineLibraryShrinkFlag (newVal, oldVal) {
      console.log(newVal, oldVal);
    },
  },
  data () {
    return {
      taskName: '',
      taskTypeSelectedFlag: 0, //点击常态任务0， 和临时紧急任务1 切换
      airway_list: [], //常态任务和临时紧急任务
      lineSelectedFlag: -1, //常态任务和临时紧急任务下面点击航线样式改变
      lines: [],
      selectKey: -1,
      // airLineLibraryShrinkFlag: false,
      loading: false,
      pageNo: 1,
      pageSize: 10,
    }
  },
  mounted () {
    Bus.$on('airline_list_2_airlinePanel', async () => {
      // 请求航线库数据 AirLineBodyID
      this.lines = [];
      this.pageNo = 1;
      this.selectKey = -1;
      // let loadingInstance = DOM.createLoading('正在加载数据', 'AirLineBodyID');
      this.loading = true;
      FlightLineList = await API.AIRWAY.Drd({ sourceType: 1, pageNo:1, pageSize: 10 });
      let sto = setTimeout(() => {
        // let res = await API.AIRWAY.getFlightLineListPage({ sourceType: 1, pageNo: 1, pageSize: 10 });
        this.queryByPage();
        this.loading = false;
        clearTimeout(sto);
      }, 100);
    });
    this.$nextTick(async () => {
      let scrollDom = document.getElementById("AirLineBodyID");
      scrollDom.addEventListener('scroll', async () => {
        if (scrollDom.scrollHeight - scrollDom.scrollTop === scrollDom.clientHeight) {
          // 发起内容重新加载的操作
          this.pageNo += 1;
          this.queryByPage();
        }
      });
    });
  },
  methods: {
    // 关掉任务库面板
    close () {
      this.taskName = '';
      Bus.$emit('taskLibraryShow', false);
    },
    // 搜索任务
    async onUavSearch() {
      this.lines = [];
      // console.log('开始查询');
      this.loading = true;
      // let res = COMMON.search(FlightLineList, this.taskName, 'title');
      if (this.taskName.trim() === '') {
        // this.lines = _.cloneDeep(FlightLineList);
        let res = await API.AIRWAY.getFlightLineListPage({ sourceType: 1, pageNo: 1, pageSize: 10 });
        this.lines = res.data;
        this.loading = false;
        return;
      }
      let res = await API.AIRWAY.getFlightLineListPage({ sourceType: 1, pageNo: 1, pageSize: 10 });
      this.lines = res.data;
      this.loading = false;
      // let a = [];
      // for (let i = 0; i < FlightLineList.length; i++) { 
      //     if (String(FlightLineList[i]?.title).indexOf(this.taskName) != -1) {
      //       a.push(FlightLineList[i]);
      //     }
      // }
      // this.lines = a;
      // console.log('查询结束');
    },
    /**
     *@Description: 在航线库中选择航线
     *@Date: 2023-10-18 16:24:57
     *@Params1: 
     *@Return1: 
    */
    async airLineSelectedHandle (v, key) {
      if (v.issafe != 1) {
        await this.$confirm('此航线为非安全航线，开始任务前请确认航线安全！', '安全确认', { customClass: "uav_controlPane", showClose: false, });
      }
      this.selectKey = key;

      console.log(v, '选择的航线数据');



      // console.log(this.selectKey, 'selectKey');
      Bus.$emit('airLineSelectedHandle', v);
      // 关闭航线库组件
      this.close();
    },
    /**
     *@Description:调用创建航线组件，也就是手动规划航线
     *@Date: 2023-10-19 10:14:17
     *@Params1: 
     *@Return1: 
    */
    createAirLine () {
      // 1 关闭航线库面板
      Bus.$emit('airLineLibraryShow', false);
      // 2 调用手动规划航线面板
      Bus.$emit('manualPanelShow', true);
    },
    /**
     *@Description: 点击展开航线库，航线库面板右移
     *@Date: 2023-10-19 14:05:17
     *@Params1: 
     *@Return1: 
    */
    openClickHandle () {
      Bus.$emit('airline-library-move-right', true);
    },
    /**@Description :  ******************************************* 前端分页查询，每次最多查询10条数据
     **@Date: 2024-06-26 14:29:09
    */
    queryByPage() {
      this.loading = true;
      let len = FlightLineList.length;
      let simpleData = [];
      // 对数据先进行过滤
      if (this.taskName.trim() != '') {
        for (let i = 0; i < len; i++) { 
          if (String(FlightLineList[i]?.title).indexOf(this.taskName) != -1) {
            simpleData.push(FlightLineList[i]);
          }
        }
      } else {
        simpleData = FlightLineList;
      }
      // 分页查询
      let res = [];
      let start = (this.pageNo - 1) * this.pageSize;
      let end = start + 10;
      for (let i = start; i < end; i++){
        if (i<simpleData.length) {
          res.push(simpleData[i]);
        }
      }
      this.lines = [...this.lines, ...res];
      this.loading = false;
    },
    /**@Description :  ******************************************* 点击查询按钮
     **@Date: 2024-06-26 14:38:53
    */
    handleClickSearch() {
      this.lines = [];
      this.pageNo = 1;
      this.queryByPage();
    },
  },
}
</script>

<style lang="scss" scoped>
.airline-library-container {
  width: 100%;
  height: 100% !important;
  background-color: #0A293A;
  z-index: 221 !important;

  .header {
    // width: 410px;
    width: 100%;
    height: 54px;
    background: #224D68;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .open {
      color: #ffffff;
      cursor: pointer;
      margin-right: 10px;
    }

    .name {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }

    .img {
      width: 20px;
      height: 20px;
      // border: 2px solid #FFFFFF;
      cursor: pointer;
    }
  }

  .task-search {
    // width: 410px;
    width: 100%;
    height: 64px;
    background: #133E55;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #133E55;

    .uav-search__input ::v-deep {
      height: 40px;
      border-radius: 4px;
      border: 0;

      .el-input__icon {
        line-height: 32px;
      }

      input {
        height: 40px;
        background-color: #0A293A;
        color: #43deff;
        border: 0px solid #315ec7;

        &::placeholder {
          font-size: 12px;
          color: #7bb5d5;
        }
      }
    }

    .uav-search__btn {
      border-radius: 4px;
      border: 1px solid;
      font-size: 14px;
      color: #fff;
      width: 42px;
      height: 40px;
      background: #4E87AC;
      border-radius: 4px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }
  }

  .content {
    // width: 410px;
    width: 100%;
    height: calc(100% - 238px);

    .title {
      width: 410px;
      height: 54px;
      background: #224D68;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .name {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .name1 {
        width: 101px;
      }

      .name2 {
        width: 130px;
      }

      .name-selected {
        color: #00F5FF;
        border-bottom: 2px solid #00F5FF;
      }
    }

    .body {
      // width: 410px;
      width: 100%;
      height: calc(100% - 54px);
      // overflow-y: auto;
      // border: 1px solid yellow;
      box-sizing: border-box;
      padding: 5px 0px;
      overflow: hidden;
      overflow-y: auto;

      .item {
        // width: 410px;
        width: 100%;
        height: 40px;
        background: #1D3652;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 0 0 0 10px;
        margin-bottom: 2px;
        cursor: pointer;
      }

      .item:hover {
        background: #0A293A;
        color: #00F5FF;
      }

      .item-selected {
        background: #0A293A;
        color: #00F5FF;
      }

      .air-line-card-selected {
        background-color: #114465;
      }
    }
  }

  .foot {
    width: 378px;
    height: 56px;
    background: #133E55;
    // background-color: #00F5FF;
    background: #133E55;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 auto;
    margin-top: -24px;

    .img {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #00F5FF;
    }
  }
}
</style>
<style lang="scss">
@import "@/styles/loader.scss";
</style>