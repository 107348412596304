var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-observe-fckernel-uav"},[_c('Header',{attrs:{"uav-data":_vm.uav.control.data}}),_c('div',{staticClass:"fckernelVideos_leftBox"},[_c('div',{staticClass:"dib wih100 h54 fckernelVideos_leftBox_title"},[_c('div',{staticClass:"title title1",class:[_vm.titleSelectedFlag == 0 ? 'title-selected' : ''],on:{"click":function($event){_vm.clickTitle(0); _vm.changeList()}}},[_vm._v("无人机列表 ")]),_c('div',{staticClass:"title title2",class:[_vm.titleSelectedFlag == 1 ? 'title-selected' : ''],on:{"click":function($event){_vm.clickTitle(1); _vm.changeList('yc')}}},[_vm._v("机巢列表 ")])]),(false)?_c('div',{staticClass:"videoBtn"},_vm._l((_vm.videoBtnList),function(item,index){return _c('div',{key:index,class:_vm.uav.itemkey == item.id ? 'videoBtn_active' : '',on:{"click":function($event){return _vm.splitScreenFn(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0):_vm._e(),(_vm.videoType)?_c('div',{staticClass:"uav-search"},[_c('el-input',{staticClass:"uav-search__input",attrs:{"clearable":"","placeholder":"请输入无人机名称/机构名称"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onUavSearch.apply(null, arguments)}},model:{value:(_vm.uavSearchContent),callback:function ($$v) {_vm.uavSearchContent=$$v},expression:"uavSearchContent"}}),_c('el-button',{staticClass:"uav-search__btn",attrs:{"icon":"el-icon-search"},on:{"click":_vm.onUavSearch}})],1):_vm._e(),(_vm.videoType)?_c('UavList',{staticClass:"uav-list",staticStyle:{"box-sizing":"border-box","padding-left":"10px"},attrs:{"containerStyle":{
      width: '390',
      height: '538',
    },"list":_vm.uav.list},on:{"videoChange":_vm.videoChange,"fn":_vm.uav_fn,"refresh":_vm.onUavSearch}}):_vm._e(),(!_vm.videoType)?_c('div',{staticClass:"cpt-observe-nest-list"},[_c('div',{staticClass:"ctx-box pl0"},[_c('div',{staticClass:"list-box pr0"},[(_vm.list && _vm.list.length)?_vm._l((_vm.list),function(item){return _c('Item',{key:item.id,attrs:{"data":item},on:{"fn":_vm.uav_fn}})}):_vm._e()],2)])]):_vm._e(),_c('pitchyaw',{staticClass:"pitchyaw",class:_vm.isFullscreenBool ? '_full_pitchyaw' : 'pitchyaw',attrs:{"uav-data":_vm.uav.control.data}})],1),(_vm.uav.videos.length > 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"right-panel",class:{
      videoBox_four: _vm.uav.itemkey == 4,
      videoBox_one: _vm.uav.itemkey == 1,
      videoBox_nine: _vm.uav.itemkey == 9,
      videoBox_sixteen: _vm.uav.itemkey == 16,
    },attrs:{"element-loading-text":"加载中..","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.9)"}},[_c('div',{staticClass:"split-screen"},[_c('div',{staticClass:"right"},[_c('img',{staticClass:"img img1",attrs:{"src":require("@/assets/img/i97.png"),"alt":""},on:{"click":function($event){return _vm.splitScreenFn(_vm.videoBtnList[0])}}}),_c('img',{staticClass:"img img2",attrs:{"src":require("@/assets/img/i95.png"),"alt":""},on:{"click":function($event){return _vm.splitScreenFn(_vm.videoBtnList[1])}}}),_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/i96.png"),"alt":""},on:{"click":function($event){return _vm.splitScreenFn(_vm.videoBtnList[2])}}}),_c('div',{staticClass:"f-img",on:{"click":function($event){return _vm.splitScreenFn(_vm.videoBtnList[3])}}},[_vm._v("16")])])]),_vm._l((_vm.uav.videos),function(video,index){return _c('div',{key:index,staticClass:"player",on:{"click":function($event){return _vm.change_active_item(index)}}},[_c('FloatPlayer',{staticClass:"wih100 ht100",class:{ active_item_style: index == _vm.uav.active_item, default_item_style: index != _vm.uav.active_item, },attrs:{"device":_vm.uav.control.device,"uav-data":_vm.uav.control.data,"_index":index,"data":video},on:{"exitVideo":_vm.exitVideo}})],1)})],2):_vm._e(),(_vm.uav.TakeOverFlag)?_c('TakeOver',{on:{"exit":function($event){_vm.uav.TakeOverFlag = false}}}):_vm._e(),_c('SideBar',{staticClass:"side-bar",attrs:{"selectedFlag":2}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }