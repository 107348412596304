<template>
  <div class="side-bar-container">
    <div
      class="item item1"
      :class="[selectedFlag == 0 ? 'item-selected' : '']"
      @click="itemClick(0)"
    >
      <img src="@/assets/img/i25.png" />
      <span>无人机</span>
    </div>
    <div
      class="item item2"
      :class="[selectedFlag == 1 ? 'item-selected' : '']"
      @click="itemClick(1)"
    >
      <img src="@/assets/img/i23.png" />
      <span>机巢应用</span>
    </div>
    <div
      class="item item3"
      :class="[selectedFlag == 2 ? 'item-selected' : '']"
      @click="itemClick(2)"
    >
      <img src="@/assets/img/i24.png" />
      <span>视频墙</span>
    </div>
  </div>
</template>

<script>
// 引用bus
import Bus from '@/assets/ligature.js';
export default {
  props: {
    selectedFlag: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // selectedFlag: 0, // 0 无人机， 1 机巢应用， 2 视频墙
    };
  },
  methods: {
    itemClick(v) {
      // this.selectedFlag = v;
      if (v == 0) {
        Bus.$emit('lianshi-type', v);
        // window.open("#/fckernel", "_blank");
        window.open('#/fckernel', '_self');
        this.$router.go(0);
      } else if (v == 1) {
        Bus.$emit('lianshi-type', v);
        // window.open("#/fckernel?page=2", "_blank");
        window.open('#/fckernel?page=2', '_self');
        this.$router.go(0);
      } else {
        Bus.$emit('lianshi-type', v);
        // window.open("#/fckernelVideos", "_blank");
        window.open('#/fckernelVideos', '_self');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar-container {
  width: 80px;
  height: calc(100% - 60px);
  background: #0e3042;

  .item {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    span {
      margin-top: 10px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .item2 {
    img {
      width: 24px;
      height: 20px;
    }
  }

  .item3 {
    img {
      width: 25px;
      height: 16px;
    }
  }

  .item-selected {
    background: #2b72ea;
  }
}
</style>
